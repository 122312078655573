import React from "react"
// import {Link} from "gatsby";
import Slider from "react-slick"

import Thermo from "../../Elements/SvgElements/Thermo"
import ScrollDownArrows from "../../Elements/Arrows/ScrollDownArrows"
import { StaticImage } from "gatsby-plugin-image"

const PREFIX_URL =
  "https://raw.githubusercontent.com/surreum/imagestore/main/refeng/headslides"

export default class HeadCarousel extends React.Component {
  constructor(props) {
    super(props)
    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
  }
  state = {
    slideIndex: 0,
    updateCount: 0,
  }

  next() {
    this.slider.slickNext()
  }
  previous() {
    this.slider.slickPrev()
  }

  render() {
    const settings = {
      dots: false,
      arrows: false,
      infinite: true,
      speed: 1500,
      slidesToShow: 1,
      slidesToScroll: 1,
      afterChange: () =>
        this.setState(state => ({ updateCount: state.updateCount + 1 })),
      beforeChange: (current, next) => this.setState({ slideIndex: next }),
    }
    return (
      <div id="headblock" className="header-slider">
        <button
          type="button"
          className="header-animated-arrows-prev"
          onClick={this.previous}
        >
          <div className="header-animated-arrow-prev header-animated-arrow-first-prev"></div>
          <div className="header-animated-arrow-prev header-animated-arrow-second-prev"></div>
        </button>
        <div className="slidenumber-new">{this.state.slideIndex + 1}</div>
        <button
          type="button"
          className="header-animated-arrows-next"
          onClick={this.next}
        >
          <div className="header-animated-arrow header-animated-arrow-first"></div>
          <div className="header-animated-arrow header-animated-arrow-second"></div>
        </button>
        <input
          className="thermo-input"
          onChange={e => this.slider.slickGoTo(e.target.value)}
          value={this.state.slideIndex}
          type="range"
          orient="vertical"
          min={0}
          max={5}
        />
        <Thermo />
        <a href="/#page2">
          <ScrollDownArrows />
        </a>
        <Slider
          ref={slider => (this.slider = slider)}
          {...settings}
          className="headblock-slider"
        >
          <div>
            <img
              src={PREFIX_URL + "/1-slide-min2.jpg"}
              alt="Рефинжиниринг-эксперты в холодоснабжении"
            />
            <div className="slideroverlay"></div>
            <div className="ref-header">
              <div className="ref-header-caption-title">Рефинжиниринг</div>
              <div className="ref-header-caption-subtitle">
                Эксперты в холодоснабжении
              </div>
              <div className="ref-header-caption-text">
                <ul>
                  <li>
                    Более 50 МВт – холодильная мощность реализованных проектов
                  </li>
                  <li>Работа с промышленно-опасными объектами</li>
                  <li>Собственное производство и прямые контракты</li>
                </ul>
              </div>
              {/* <div className="promo-main">
                          <figure style={{display: 'block'}}>
                              <img style={{width: '20%', height: 'auto'}} 
                              src="https://raw.githubusercontent.com/surreum/imagestore/main/refeng/promo/logodtmain.png" 
                              alt="Логотип Агропродмаш" />
                              <figcaption style={{}}><em>Мы на выставке "Dairytech 2023"</em></figcaption>
                              <p style={{fontSize: "1.2em", fontWeight: '700'}}>25–27 января 2023</p>
                              <span><Link to="/promo">Подробнее</Link></span>
                          </figure>
                        </div> */}
            </div>
          </div>
          <div>
            <img
              src={PREFIX_URL + "/2-slide-min.jpg"}
              alt="Рефинжиниринг-Транскритические системы и другие инновации"
            />
            <div className="slideroverlay"></div>
            <div className="ref-header">
              <div className="ref-header-caption-title">
                Уникальные проекты и смелые решения
              </div>
              <div className="ref-header-caption-subtitle">
                Мы – профессионалы и энтузиасты своего дела, идущие в ногу с
                мировыми тенденциями
              </div>
              <div className="ref-header-caption-text">
                <p>
                  Транскритические системы, интеграция систем отопления,
                  вентиляции и ГВС в общую систему рекуперации тепла,
                  энергоэффективная оттайка – актуальность этих и других
                  современных концепций мы можем доказать на примере работающих
                  объектов
                </p>
              </div>
            </div>
          </div>
          <div>
            <img
              src={PREFIX_URL + "/3-slide-min.jpg"}
              alt="Рефинжиниринг-Собственное производство и импорт"
            />
            <div className="slideroverlay"></div>
            <div className="ref-header">
              <div className="ref-header-caption-title">
                Собственное производство и импорт
              </div>
              <div className="ref-header-caption-subtitle">
                Будьте уверены – Вы получите наилучшее соотношение цены и
                качества на рынке
              </div>
              <div className="ref-header-caption-text">
                <ul>
                  <li>
                    Прямые контракты с ведущими производителями компрессоров,
                    теплообменников, холодильных компонентов и автоматики
                  </li>
                  <li>Отлаженный импорт и логистика</li>
                  <li>Штатные конструкторы</li>
                  <li>
                    Современная производственная площадка по сборке холодильных
                    агрегатов
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div>
            <img
              src={PREFIX_URL + "/4-slide-min.jpg"}
              alt="Рефинжиниринг-Круглосуточный сервис"
            />
            <div className="slideroverlay"></div>
            <div className="ref-header">
              <div className="ref-header-caption-title">
                Круглосуточный сервис
              </div>
              <div className="ref-header-caption-subtitle">
                Особое внимание мы уделяем выполнению гарантийных обязательств и
                последующему сервисному обслуживанию наших объектов
              </div>
              <div className="ref-header-caption-text">
                <p>
                  Высококвалифицированные специалисты укомплектованы всем
                  необходимым измерительным, аналитическим и ремонтным
                  оборудованием, а также служебными автомобилями и программным
                  обеспечением для решения задач любой сложности
                </p>
              </div>
            </div>
          </div>
          <div>
            <img
              src={PREFIX_URL + "/5-slide-min.jpg"}
              alt="Рефинжиниринг-Финансовая надежность и прозрачность"
            />
            <div className="slideroverlay"></div>
            <div className="ref-header">
              <div className="ref-header-caption-title">
                Надежность и прозрачность
              </div>
              <div className="ref-header-caption-subtitle">
                Обратившись к нам, вы гарантированно снижаете риски. Мы очень
                дорожим нашей репутацией.
              </div>
              <div className="ref-header-caption-text">
                <ul>
                  <li>Оборот в 2023 году составил 1 млрд. рублей</li>
                  <li>Полное отсутствие судебных разбирательств</li>
                  <li>Платим налоги всегда вовремя</li>
                  <li>Работаем с 2013 года</li>
                </ul>
              </div>
            </div>
          </div>
          <div>
            <StaticImage
              src="../../../images/mainpage/Mashzal_Fan-Fan_UHD_8k_black.png"
              alt="Рефинжиниринг-Высочайшие внутренние стандарты качества монтажа"
            />
            {/* <img
              src={PREFIX_URL + "/6-slide-min.jpg"}
              alt="Рефинжиниринг-Высочайшие внутренние стандарты качества монтажа"
            /> */}
            <div className="slideroverlay"></div>
            <div className="ref-header">
              <div className="ref-header-caption-title">
                Качество и лояльность
              </div>
              <div className="ref-header-caption-subtitle">
                Есть немного компаний в России, которые могут конкурировать с
                нами в области качества. Мы делаем свою работу так, как сделали
                бы её для себя. Став нашим клиентом однажды, Вы непременно
                захотите работать снова, потому что будете доверять нам.
              </div>
              <div className="ref-header-caption-text">
                <ul>
                  <li>Высочайшие внутренние стандарты качества монтажа</li>
                  <li>Проектирование и конструирование в 3D</li>
                  <li>Внимание к деталям</li>
                  <li>Полнота эксплуатационной документации</li>
                </ul>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    )
  }
}
